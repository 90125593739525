import React from "react";

function ArrowSvg({ style, width = 27, height = 16, viewBox = "13 0 32 18" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      class="arrow"
    >
      <g fill={style?.color} fillRule="evenodd" class="arrow__path">
        <path d="M0 7.325h24.729V9.22H0z" />
        <path d="M19.343.687l7.472 7.585-1.305 1.362-7.472-7.585z" />
        <path d="M18.002 14.595l7.437-7.683 1.376 1.36-7.364 7.698z" />
      </g>
      <g fill={style?.color} fillRule="evenodd" class="arrow-fixed__path">
        <path d="M0 7.325h24.729V9.22H0z" />
        <path d="M19.343.687l7.472 7.585-1.305 1.362-7.472-7.585z" />
        <path d="M18.002 14.595l7.437-7.683 1.376 1.36-7.364 7.698z" />
      </g>
    </svg>
  );
}

export default ArrowSvg;
