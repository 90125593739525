import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Marquee from "react-fast-marquee";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import PrismicLink from "./prismicLink";
import ArrowSvg from "./arrowSvg";

const query = graphql`
  query GlobalHeaderPromoBannerQuery {
    site {
      id
    }
    prismicSiteSettings {
      data {
        global_header_promo_banner_bold_text
        global_header_promo_banner_light_text
        global_header_promo_banner_link {
          url
        }
      }
    }
  }
`;

const GlobalHeaderPromoBanner = () => {
  const staticData = useStaticQuery(query);
  const data = useMergePrismicPreviewData(staticData);
  const bannerBoldText = data?.prismicSiteSettings?.data?.global_header_promo_banner_bold_text;
  const bannerLightText = data?.prismicSiteSettings?.data?.global_header_promo_banner_light_text;
  const bannerLink = data?.prismicSiteSettings?.data?.global_header_promo_banner_link;
  return (
    <div className="global-header-promo-banner" role="contentinfo">
      <PrismicLink link={bannerLink} className="global-header-promo-banner__link">
        <div className="container">
          <div className="global-header-promo-banner__text">
              <strong>{(bannerBoldText)}</strong>
              <Marquee className="global-header-promo-banner__text__marquee">
                <p>{(bannerLightText)}</p>
              </Marquee>
              <ArrowSvg width={50} viewBox="13 0 1 18" />
          </div>
        </div>
      </PrismicLink>
    </div>
  );
};


export default GlobalHeaderPromoBanner;
