import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import "../styles/main.scss";
import Footer from "./footer";
import Header from "./header/header";
import GlobalHeaderPromoBanner from "./globalHeaderPromoBanner";
import {
  useMergePrismicPreviewData,
  withPrismicPreview,
} from "gatsby-plugin-prismic-previews";

const query = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
      }
    }
    prismicSiteSettings {
      _previewable
      data {
        social_twitter {
          url
        }
        social_medium {
          url
        }
        social_linkedin {
          url
        }
        social_instagram {
          url
        }
        social_facebook {
          url
        }
        social_vimeo {
          url
        }
        modex_link {
          url
        }
        main_navigation {
          main_navigation_link {
            url
            target
          }
          main_navigation_name {
            text
          }
        }
      }
    }
  }
`;

const duration = 0.5;
const variants = {
  layoutInitial: {
    opacity: 0,
  },
  layoutEnter: {
    opacity: 1,
    transition: {
      duration: duration,
      when: "beforeChildren",
    },
  },
  layoutExit: {
    opacity: 0,
    transition: { duration: duration },
  },
};

const Layout = ({ children }) => {
  const staticData = useStaticQuery(query);
  const data = useMergePrismicPreviewData(staticData);

  return (
    <>
      <GlobalHeaderPromoBanner />
      <div className="main-container">
        <Header node={data?.prismicSiteSettings?.data} />
        <div>
          <AnimatePresence mode="wait" initial={true}>
            <motion.div
              variants={variants}
              initial="layoutInitial"
              animate="layoutEnter"
              exit="layoutExit"
              className="container"
              role="main"
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default withPrismicPreview(Layout);
